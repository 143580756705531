<template>
  <div class="row g-2 mb-4" v-if="!hide">
    <div class="col-md-7 mb-2 mb-md-0">
      <div class="form-label text-center">Card Number:</div>
      <div class="position-relative px-3">
        <div class="form-control position-absolute start-0" :class="{error: cardErrorsValue.cardNumber}"></div>
        <div id="card-number-element" class="field" @change="cehck"></div>
      </div>
      <div class="isError" v-if="cardErrorsValue.cardNumber" >
        {{ cardErrorsValue.cardNumber }}
      </div>
    </div>
    <div class="col-md-5 mb-2 mb-md-0">
      <div class="row g-2">
        <div class="col-6">
          <div class="form-label text-center">Expiry Date:</div>
          <div class="position-relative px-3">
            <div class="form-control position-absolute start-0" :class="{error: cardErrorsValue.cardExpiryDate}"></div>
            <div id="card-expiry-element" class="field"></div>
          </div>
          <div class="isError" v-if="cardErrorsValue.cardExpiryDate" >
            {{ cardErrorsValue.cardExpiryDate }}
          </div>
        </div>
        <div class="col-6">
          <div class="form-label text-center">Security Code:</div>
          <div class="position-relative px-3">
            <div class="form-control position-absolute start-0" :class="{error: cardErrorsValue.cardCvc}"></div>
            <div id="card-cvc-element" class="field"></div>
          </div>
          <div class="isError" v-if="cardErrorsValue.cardCvc" >
            {{ cardErrorsValue.cardCvc }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default ({
  name: 'StripeCardInput',
  props: ['elements', 'card', 'cardErrors', 'hide'],
  emits: ['update:card', 'update:cardErrors'],
  computed: {
    cardValue: {
      get () {
        return this.card
      },
      set (val) {
        this.$emit('update:card', val)
      }
    },
    cardErrorsValue: {
      get () {
        return this.cardErrors
      },
      set (val) {
        this.$emit('update:cardErrors', val)
      }
    }
  },
  async mounted () {
    if (this.elements) {
      await this.createCardElement()
    }
  },
  methods: {
    createCardElement () {
      console.log('createCardElement')
      const style = {
        base: {
          iconColor: '#ffc86d',
          color: '#ffc86d',
          fontWeight: 500,
          fontFamily: 'inherit',
          fontSize: '16px',
          '::placeholder': {
            color: '#589562'
          }
        }
      }
      const centerstyle = {
        base: {
          color: '#ffc86d',
          fontWeight: 500,
          fontFamily: 'inherit',
          fontSize: '16px',
          textAlign: 'center',
          '::placeholder': {
            color: '#589562',
            textAlign: 'center'
          }
        }
      }
      this.cardValue.cardNumber = this.elements.create('cardNumber', {
        style: style,
        showIcon: true,
        iconStyle: 'solid',
        placeholder: 'Card Number'
      })
      this.cardValue.cardExpiryDate = this.elements.create('cardExpiry', {
        style: centerstyle,
        placeholder: 'MM/YY'
      })
      this.cardValue.cardCvc = this.elements.create('cardCvc', {
        style: centerstyle,
        placeholder: 'CVC'
      })
      this.cardValue.cardNumber.mount('#card-number-element')
      this.cardValue.cardExpiryDate.mount('#card-expiry-element')
      this.cardValue.cardCvc.mount('#card-cvc-element')
      this.listenForErrors()
    },
    listenForErrors () {
      this.cardValue.cardNumber.addEventListener('change', this.clearNumberError)
      this.cardValue.cardExpiryDate.addEventListener('change', this.clearExpiryError)
      this.cardValue.cardCvc.addEventListener('change', this.clearCvcError)
    },
    clearCvcError () {
      this.cardErrorsValue.cardCvc = null
    },
    clearExpiryError () {
      this.cardErrorsValue.cardExpiryDate = null
    },
    clearNumberError () {
      this.cardErrorsValue.cardNumber = null
    }
  }
})
</script>

<style scoped>
  .form-control {
    background-color: var(--green-color-dark);
    color: var(--gold-color);
    border-radius: 2rem;
    border: 2px solid var(--green-color-dark);
    font-size: 1rem;
    height: calc(2.5rem + 4px);
    padding: 0 1rem;
    font-weight: 500;
    text-align: left;
  }
  .field {
    padding-top: 13px;
    padding-bottom: 13px;
  }
  </style>
